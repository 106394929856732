<template>
  <sidebar-template title="Feature Details" :closeAction="closeAction">
    <template #content>
      <div v-if="feature && feature!=null"
        class="w-100 h-auto d-flex flex-column align-items-center justify-content-start"
      >
        <div class="w-100 d-flex align-items-start justify-content-start">
          <div
            class="d-flex flex-column align-items-start justify-content-start"
          >
            <h3 class="mb-50">
              <span class="align-middle">{{ feature.title }}</span>
            </h3>
            <p>{{ feature.description }}</p>

            <table class="table table-bordered table-sm">
              <tr>
                <td>Module:</td>
                <td>
                  <b-badge variant="primary" class="ml-50">{{
                    feature.module
                  }}</b-badge>
                </td>
              </tr>

              <tr>
                <td>Unit:</td>
                <td>
                  <b-badge variant="success" class="ml-50">{{
                    feature.unit
                  }}</b-badge>
                </td>
              </tr>

              <tr>
                <td>Price(USD):</td>
                <td>
                  <span class="ml-50 font-weight-bold">{{
                    feature.tariff
                  }}</span>
                </td>
              </tr>
            </table>
           
          </div>
        </div>
      </div>
    </template>
  </sidebar-template>
</template>

<script>
import SidebarTemplate from "../../components/SidebarTemplate.vue";
import { BBadge, BButton } from "bootstrap-vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import FeatureMixins from "../../../mixins/FeatureMixins";

export default {
  mixins: [ResponseMixins, FeatureMixins],

  components: {
    SidebarTemplate,
    BBadge,
    BButton,
  },

  data() {
    return {
      feature: null,
    };
  },

  mounted() {
    this.load();
  },

  props: {
    closeAction: {
      type: Function,
      required: true,
    },
    featureId: {
      type: String,
      required: true,
    },
  },

  methods: {
    load() {
      this.getFeatureDetailsAndSetData(this.featureId);
    },

    getFeatureDetailsAndSetData(id) {
      this.getFeature(id)
        .then((res) => {
          console.log(res);
          this.feature = res.data.data;
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        });
    },
  },
};
</script>

<style lang="scss">
.custom-collapse {
  .card {
    .card-header {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0.5rem;
    }

    .card-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>