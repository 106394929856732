<template>
  <div class="w-100 h-auto">
    <b-modal ref="edit-feature-modal" hide-footer title="Edit Feature">
      <feature-edit-form
        :closeAction="hideEditFeatureModal"
        :featureId="selectedPlanId"
        :reloadParent="load"
      />
    </b-modal>

    <b-sidebar
      id="sidebar-feature-details"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
      lazy
      width="420px"
    >
      <feature-details-sidebar
        :closeAction="closefeatureDetailsSidebar"
        :featureId="selectedPlanId"
      />
    </b-sidebar>

    <div class="card">
      <div class="card-header pb-75" style="padding-right: 0">
        <div class="w-100 row justify-content-between align-items-center">
          <div
            class="d-flex flex-column align-items-start justify-content-start col-md-6 col-sm-12"
          >
            <h3 class="mb-25 font-weight-bolder">Plans</h3>
            <p class="mb-50">Create & Manage your plans.</p>
          </div>
          <div class="col-md-6 col-sm-12">
            <div
              class="w-100 d-flex align-items-center justify-content-md-end justify-content-sm-end"
            >
              <b-button size="sm" variant="success" @click="handleNewPlanClick"
                ><feather-icon icon="PlusIcon" class="mr-50" />Create New
                Plan</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div class="pb-25 pt-0 w-100 row">
        <div class="app-fixed-search col-md-8 col-sm-12">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon
                v-if="filters.search == null || filters.search == ''"
                icon="SearchIcon"
                class="text-muted"
              />
              <feather-icon
                @click="
                  () => {
                    filters.search = null;
                  }
                "
                v-else
                icon="XIcon"
                class="text-danger cursor-pointer"
              />
            </b-input-group-prepend>
            <b-form-input
              v-model="filters.search"
              placeholder="Search name..."
              debounce="500"
              size="md"
            />
          </b-input-group>
        </div>
      </div>
      <div class="position-relative table-responsive" style="min-height: 50vh">
        <b-overlay
          variant="white"
          :show="showOverlay"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <div class="table-responsive" style="min-height: 50vh">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th scope="col">Activate</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Features</th>
                  <th scope="col">Prices</th>
                  <th scope="col" class="text-center">Actions</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <template v-if="Object.keys(plans).length > 0">
                  <tr
                    role="row"
                    v-for="(plan, key) in plans"
                    :key="key"
                    @click="handleDetailViewClick(plan._id)"
                  >
                    <!-- <td scope="row" style="width: 120px">
                      {{ index + pagination.from }}
                    </td> -->

                    <td scope="row" style="width: 80px">
                      <b-form-checkbox
                        @click.native.capture.stop
                        @change="(value) => checkboxChanged(value, key)"
                        v-model="plan.status"
                        :value="1"
                        :unchecked-value="2"
                        switch
                        inline
                      >
                      </b-form-checkbox>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start"
                      >
                        <p
                          class="mb-0 font-weight-bold text-truncate"
                          style="max-width: 240px"
                          v-b-tooltip.hover.bottom.v-primary
                          :title="plan.title"
                        >
                          {{ `${plan.title}` }}
                        </p>
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex flex-column align-items-start justify-content-start"
                      >
                        <p
                          class="mb-0 font-weight-bold text-truncate"
                          style="max-width: 340px"
                          v-b-tooltip.hover.bottom.v-primary
                          :title="plan.description"
                        >
                          {{ `${plan.description}` }}
                        </p>
                      </div>
                    </td>

                    <td role="cell" style="width: 80px">
                      <div
                        class="d-flex align-items-center justify-content-center"
                        v-if="plan.features && plan.features != null"
                      >
                        <b-badge pill variant="warning">{{
                          `${plan.features.length}`
                        }}</b-badge>
                      </div>
                    </td>

                      <td role="cell" style="width: 80px">
                      <div
                        class="d-flex align-items-center justify-content-center"
                        v-if="plan.prices && plan.prices != null"
                      >
                        <b-badge pill variant="warning">{{
                          `${plan.prices.length}`
                        }}</b-badge>
                      </div>
                    </td>

                    <td role="cell">
                      <div
                        class="d-flex justify-content-center align-items-center"
                      >
                        <b-button
                          :disabled="plan.status == 1"
                          @click.stop="handleEditClick(key)"
                          variant="flat-info"
                          class="btn-icon"
                          ><feather-icon icon="EditIcon"
                        /></b-button>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <empty-table-section title="Plans Empty">
                    <template #content>
                      <p class="font-weight-bold text-center">
                        No plans have been found.
                      </p>
                    </template>
                  </empty-table-section>
                </template>
              </tbody>
            </table>
          </div>
        </b-overlay>
      </div>
      <div class="card-body my-0 py-0">
        <div class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="filters.page"
            :total-rows="pagination.total"
            :per-page="pagination.perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BTable } from "bootstrap-vue";

import {
  BPagination,
  BPaginationNav,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BForm,
  BFormInput,
  BBadge,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  VBTooltip,
  BSidebar,
  BOverlay,
  BFormCheckbox,
} from "bootstrap-vue";
import ResponseMixins from "@/mixins/ResponseMixins";

import EmptyTableSection from "../components/EmptyTableSection.vue";
import PlanDetailsSidebar from "./components/PlanDetailsSidebar.vue";
import PlanMixins from "../../mixins/PlanMixins";

export default {
  mixins: [ResponseMixins, PlanMixins],
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BForm,
    BFormInput,
    BBadge,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BSidebar,
    BOverlay,
    EmptyTableSection,
    PlanDetailsSidebar,
    BFormCheckbox,
  },
  data() {
    return {
      selectedPlanId: null,
      pagination_pos: "center",

      showOverlay: false,
      pagination: {
        perPage: 10,
        total: 1,
        from: 1,
      },

      filters: {
        search: null,
        page: 1,
        include_inactive:1,
      },

      plans: {},
      activatedPlans: [],
    };
  },
  watch: {
    filters: {
      handler: function (newValues) {
        this.getPlansAndSetData(newValues);
      },
      deep: true,
    },
  },
  mounted: function () {
    this.load();
  },
  methods: {
    load: function () {
      this.getPlansAndSetData(this.filters);
    },

    handleEditClick(id) {
      this.$router.push(`/edit-plan/${id}`);
    },

    checkboxChanged(value, id) {
      this.showOverlay = true;
      console.log(value);
      this.setPlanStatus(value, id)
        .then((res) => {
          console.log(res);
          this.handleResponse(res);
        })
        .catch((err) => {
          this.handleError(err);
          console.log(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    handleNewPlanClick() {
      this.$router.push("/new-plan");
    },

    handleEditFeatureClick(id) {
      this.selectedPlanId = id;
      this.showEditFeatureModal();
    },

    showEditFeatureModal() {
      this.$refs["edit-feature-modal"].show();
    },
    hideEditFeatureModal() {
      this.$refs["edit-feature-modal"].hide();
    },

    handleDetailViewClick(id) {
      this.selectedPlanId = id;
      this.$router.push(`/plan/${id}`);
    },

    getPlansAndSetData(params) {
      this.showOverlay = true;
      this.getPlans(params)
        .then((res) => {
          console.log(res);
          const newPlans = {};
          res.data.data.data.forEach((plan) => {
            newPlans[plan._id] = plan;
          });
          this.plans = newPlans;
          this.$set(this.filters, "page", res.data.data.current_page);
          this.$set(this.pagination, "perPage", res.data.data.per_page);
          this.$set(this.pagination, "total", res.data.data.total);
          this.$set(this.pagination, "from", res.data.data.from);
        })
        .catch((err) => {
          console.log(err);
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
th {
  background: #472183 !important;
  color: #fff;
  text-transform: capitalize !important;
}
</style>
  
<style lang="css" scoped>
.table th {
  text-transform: none;
}
</style>
  
<style lang="scss">
.app-fixed-search {
  background-color: transparent;

  .input-group:focus-within {
    box-shadow: none;
  }

  input,
  .input-group-text {
    border: 0;
    background-color: transparent;
  }
}
</style>